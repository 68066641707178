.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  display: flex;
    flex-direction: column;
    gap: 20px;
}

h1 {
  text-align: center;
}

textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

pre {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 4px;
}